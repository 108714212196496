import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { ClarityConfig } from '@mindsciences/clarity-core/app/config/clarity.config';
import { isAuthenticating } from '@mindsciences/clarity-core/app/store/sensitive/selectors/auth.selectors';
import { SessionState } from '@mindsciences/clarity-core/app/store/session/session.reducers';
import { LoadingService } from '@mindsciences/clarity-core/app/services/loading.service';

import { Browser } from '@capacitor/browser';
import { LoggerService } from '@mindsciences/clarity-core/app/services/logger.service';

export interface SharecareLoginInitParams {
  [param: string]: string;
}

export enum SharecareSSOAuthError {
  user_already_has_a_licence_subscription = 'user_already_has_a_licence_subscription',
  user_already_has_a_itunes_subscription = 'user_already_has_a_itunes_subscription',
  user_already_has_a_googleplay_subscription = 'user_already_has_a_googleplay_subscription',
  user_already_has_a_stripe_subscription = 'user_already_has_a_stripe_subscription',
  not_qualified_error = 'not_qualified_error'
}

@Injectable({providedIn: 'root'})
export class SharecareSSOService {

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);

  private sharecareAuthParams: SharecareLoginInitParams = {};
  private login_hint: string;

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private loading: LoadingService,
    private logger: LoggerService
  ) {
  }

  get sharecareSSOLink() {
    if (!this.config.isDevice) {
      if (this.login_hint) {
        return this.getAppSsoLink();
      }

      return this.getOldAppSsoLink();
    }

    return this.getBackendSsoLink();
  }

  get appUri() {
    if (!this.config.isDevice) {
      const location = (window as any).location;

      return location.origin + location.pathname + location.search + `#${this.config.env.preauthPath}`;
    }

    if (this.config.isCTQ()) {
      return 'cravingtoquit://preauth';
    }

    if (this.config.isUA()) {
      return 'unwindinganxiety://preauth';
    }

    if (this.config.isERN ()) {
      return 'eatrightnow://preauth';
    }
  }

  getBackendSsoLink() {
    const sharecareAuthParams = this.joinSharecareAuthParams();
    const authParamsAnd = sharecareAuthParams ? sharecareAuthParams + '&' : '';

    const queryString = `${authParamsAnd}app_uri=${encodeURIComponent(this.appUri)}`;

    const link = `${this.config.env.apiUseSsl
      ? 'https://'
      : 'http://'}${this.config.apiHost}/auth/sharecare?login_hint=${encodeURIComponent(this.login_hint)}&${queryString}`;

    console.log('SSO via Backend', link);

    return link;
  }

  // TODO: kept temporarily for testing purposes
  private getOldAppSsoLink() {
    const {sharecareClientId, sharecareOauthUrl} = this.config.env;
    const link = `${sharecareOauthUrl}?client_id=${sharecareClientId}&response_type=code&redirect_uri=${this.getRedirectUri()}`;

    console.log('SSO via App', link);

    return link;
  }

  private getAppSsoLink() {
    const {sharecareClientId, sharecareOauthUrl} = this.config.env;
    const link = `${sharecareOauthUrl}?client_id=${sharecareClientId}&response_type=code&login_hint=${
      encodeURIComponent(this.login_hint)}&redirect_uri=${this.getRedirectUri()}`;

    console.log('SSO via App with login hint', link);

    return link;
  }

  private getRedirectUri() {
    const uri = this.config.redirectUri + this.getRedirectUriParams();

    return encodeURIComponent(uri);
  }

  private getRedirectUriParams() {
    if (!this.sharecareAuthParams) {
      return '';
    }

    return '?' + this.joinSharecareAuthParams();
  }

  private joinSharecareAuthParams(encode = false) {
    return Object.keys(this.sharecareAuthParams)
      .map(key => encode
        ? `${key}=${encodeURIComponent(this.sharecareAuthParams[key])}`
        : `${key}=${this.sharecareAuthParams[key]}`)
      .join('&');
  }

  init(params: SharecareLoginInitParams = {}, login_hint?: string) {
    this.loading.showLoadingOverlay('', true);

    if (login_hint) {
      this.login_hint = login_hint;
    }

    if (params && Object.keys(params).length > 0) {
      this.sharecareAuthParams = params;
    }

    if (!this.config.isDevice) {
      return this.startLoginInCurrentWindow();
    }

    this.startLoginInCapacitorBrowser();
  }

  startLoginInCapacitorBrowser() {
    const url = this.sharecareSSOLink;

    Browser.removeAllListeners()
      .then(() => {
        Browser.addListener('browserFinished', () => {
          console.log('Capacitor Browser closed!');
          this.loading.hideLoadingOverlay();
        });

        console.log('Opening URL in Capacitor Browser!', url);

        Browser.open({url});
      })
      .catch((error) => {
        this.logger.error('Error while starting login in Capacitor Browser', error, 'Sharecare SSO');
      });
  }

  startLoginInCurrentWindow() {
    (window as any).location.replace(this.sharecareSSOLink);
  }

}
