import { Injectable } from '@angular/core';


import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from 'src/app/services/logger.service';
import { UpdateUserDataType, UserAnalyticsInterface } from '../analytics.interface';

// Adobe doesn't provide us typescript, so we created this partial
// interface to serve us with only the methods we're currently using
// If you intend to use other methods, please extend these interfaces based
// on the adobe documentation (https://experienceleague.adobe.com/docs/experience-platform/tags/client-side/satellite-object.html)
interface IAdobeIdentityService {
  getMarketingCloudVisitorID(): string;
}

interface IAdobeSatellite {
  track(identifier: string, detail?: any): void;
  getVisitorId(): IAdobeIdentityService;
}

@Injectable({providedIn: 'root'})
export class AdobeAnalyticsService implements UserAnalyticsInterface {

  public SERVICE_NAME = 'adobe-analytics';

  constructor(
    public config: ClarityConfig,
    public logger: LoggerService
  ) {
  }

  // @see https://experienceleague.adobe.com/docs/experience-platform/tags/client-side/satellite-object.html?lang=en
  get satellite(): IAdobeSatellite {
    const satellite = window['_satellite'];

    if (!satellite) {
      console.error('window._satellite not found. Be sure the Adobe Script tag is included at the page');
    }

    return satellite;
  }

  public initialize(): Promise<any> {
    return Promise.resolve();
  }

  public async resetService(): Promise<any> {
    // @todo

    return Promise.resolve();
  }

  public registerUser(data: UpdateUserDataType): Promise<any> {
    // @todo

    return Promise.resolve();
  }

  public async updateUser(data: UpdateUserDataType): Promise<any> {
    // @todo

    return Promise.resolve();
  }

  async trackEvent(eventName: string, data = {}) {
    this.logger.debug('Adobe tracking event', eventName);

    try {
      this.satellite.track(eventName, data);
    } catch (error) {
      this.logger.error('Error caught tracking event to Adobe', error, this.constructor.name);

      return Promise.resolve(false);
    }
  }

  getExperienceCloudId(): Promise<string | void> {
    return this.retryExperienceCloudId().catch(() => {
      this.logger.error('getExperienceCloudId', 'error retrieveing adobe experience cloud id');
    });
  }

  private retryExperienceCloudId(retries = 10, delay = 1000): Promise<string> {
    this.logger.debug('About to retry getExperienceCloudId');

    return new Promise<string>((resolve, reject) => {
      const id = this.tryGetExperienceCloudId();

      if (id) {
        return resolve(id);
      }

      if (retries > 0) {
        const wait = new Promise(r => setTimeout(r, delay));

        return wait
          .then(() => this.retryExperienceCloudId(retries - 1, delay))
          .then(resolve);
      }

      return reject();
    });
  }

  /**
   * This retrieves `Marketing Cloud Visitor ID`, which has been rebranded to `Experience Cloud ID`
   * It requires the configuration of the extension `Experience Cloud ID Service` at the Adobe Platform
   *
   * @see https://experienceleague.adobe.com/docs/experience-platform/tags/client-side/satellite-object.html?lang=en#getvisitorid
   * @see https://experienceleague.adobe.com/docs/id-service/using/id-service-api/methods/getmcvid.html?lang=en
   */
  private tryGetExperienceCloudId(): string {
    try {
      return this.satellite.getVisitorId().getMarketingCloudVisitorID();
    } catch (error) {
      this.logger.debug('tryGetExperienceCloudId error', error);
    }
  }
}
