import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { TapticService } from '../../services/taptic.service';
import { IonRange } from '@ionic/angular';
import { getFormattedTimeInMinutesAndSeconds } from '../../utils/format-time';
import { LoggerService } from '../../services/logger.service';
import { ClarityConfig } from '../../config/clarity.config';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { PluginListenerHandle } from '@capacitor/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { BrightcoveWebAudioPlayerService } from 'src/app/services/brightcove/brightcove-web-audio-player.service';
import { BrightcoveWebPlayerLoaderService } from 'src/app/services/brightcove/brightcove-web-player-loader.service';
import { AudioPlayerStatus } from './audio-player-status.enum';
import { BrightcoveNativeAudioPlayerService } from '../../services/brightcove/brightcove-native-audio-player.service';
import { AlertsService } from '../../services/alerts.service';
import { AudioNotificationOptions, BrightcovePlayer } from 'capacitor-brightcove-player';
import { ConnectivityService } from '../../services/connectivity.service';
import { AudioSubtitlesService } from 'src/app/services/brightcove/audio-subtitles.service';
import { getSubtitlesLanguage } from 'src/app/store/persistent/media/media.selectors';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { AudioCue } from 'src/app/store/normalized/schemas/mediaFile.schema';
import { SetSubtitles } from 'src/app/store/persistent/media/media.actions';
import { PLAYER_ERROR_CODES } from './player-errors.enum';
import { fadeInOut } from '@mindsciences/utils';

@Component({
  selector: 'cl-audio-player-brightcove',
  styleUrls: ['audio-player-brightcove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
  template: `
    <div class="page-card">
      <div #audio style="display:none"></div>

      <div
        *ngIf="loadedSubtitles && selectedLang !== 'Off'"
        class="subtitles"
        [@fadeInOut]
        [class.smaller-subtitles]="currentSubtitle?.length >= 150">
        {{ currentSubtitle }}
      </div>

      <div class="audio-player">
        <ion-range #rangeBar
          [ngClass]="{'ion-range-disabled': (networkError || technicalError)}"
          (ionStart)="onSeekStart()"
          (ionEnd)="onSeekEnd()"
          (ionChange)="onRangeChange($event)"
          max="{{audioTotalLength || 100}}"
          min="0"
          aria-hidden="true">
        </ion-range>
        <div class="slider-infos">
          <ion-label>{{ displayCurrentTime }}</ion-label>
          <ion-label>{{ displayTotalLength }}</ion-label>
        </div>
        <div class="controls">
          <div class="seek backward" [ngClass]="{'disabled': networkError || technicalError}" (click)="backwardFifteenSec()">
            <ion-icon name="play-back"></ion-icon>
            <span>{{'common.15_sec' | translate}}</span>
          </div>
          <cl-play-button
            [disabled]="networkError || technicalError"
            [loading]="loading && !(networkError || technicalError)"
            [playing]="playing"
            (click)="play()"
            [attr.aria-label]="playing ? 'pause' : 'play'"
            role="button"
          >
          </cl-play-button>
          <div class="seek forward" [ngClass]="{'disabled': networkError || technicalError}" (click)="forwardFifteenSec()">
            <span>{{'common.15_sec' | translate}}</span>
            <ion-icon name="play-forward"></ion-icon>
          </div>
        </div>
        <div *ngIf="languages?.length > 0 && (connectivityService.isOnline())" [@fadeInOut] class="subtitles-selection">
          <ion-list class="subtitles-list">
            <ion-item lines="none">
              <ion-icon name="logo-closed-captioning"></ion-icon>
              <ion-spinner *ngIf="loadingSubtitles" name="crescent"></ion-spinner>
              <ion-select *ngIf="!loadingSubtitles" interface="action-sheet" [value]="selectedLang" (ionChange)="languageChange($event)">
                <ion-select-option value="Off">{{'lessons.off' | translate}}</ion-select-option>
                <ion-select-option *ngFor="let lang of languages" [value]=lang>{{ getLanguageName(lang) }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </div>
  `
})
export class AudioPlayerBrightcoveComponent implements OnInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;

  @ViewChild('rangeBar') rangeBar: IonRange;
  @ViewChild('audio', { static: true }) webPlayerElement: ElementRef;

  @Input() brightcove_key: string;
  @Input() controls: Observable<string>;
  @Input() autoplay = true;
  @Input() hasPopupBefore = false;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();

  playing = false;
  loading = true;
  isSeeking = false;
  technicalError = false;
  networkError = false;
  playedMinimumEmitted = false;
  currentTimeInSeconds: number;
  audioTotalLength = 0;
  displayTotalLength = '';
  notificationCloseListener: PluginListenerHandle;
  currentSubtitle: string;
  languages: string[];
  userSettingsLanguage: string;
  cues: AudioCue[] = [];
  selectedLang = 'Off';
  loadingSubtitles: boolean;
  loadedSubtitles = false;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private subtitleLanguageSubscription: Subscription;
  private closePressed = false;

  audioPlayerService;

  constructor(
    private tapticService: TapticService,
    private brightcoveWebPlayerLoaderService: BrightcoveWebPlayerLoaderService,
    private nativeAudioPlayerService: BrightcoveNativeAudioPlayerService,
    private webAudioPlayerService: BrightcoveWebAudioPlayerService,
    private alertsService: AlertsService,
    private logger: LoggerService,
    public connectivityService: ConnectivityService,
    private config: ClarityConfig,
    private cdr: ChangeDetectorRef,
    private audioSubtitlesService: AudioSubtitlesService,
    private store: Store<State>
  ) {
    if (!this.config.isDevice && !this.brightcoveWebPlayerLoaderService.isPlayerLoaded()) {
      this.brightcoveWebPlayerLoaderService.forcePlayerLoad();
    }
  }

  get displayCurrentTime() {
    if (isNaN(this.currentTimeInSeconds)) {
      return '00:00';
    } else {
      return getFormattedTimeInMinutesAndSeconds(this.currentTimeInSeconds);
    }
  }

  async ngOnInit() {
    if (!this.brightcove_key) {
      this.logger.error('BrightcoveAudioPlayerError', 'Audio player requested, but no Brightcove key provided');
      this.technicalError = true;
    }

    this.audioPlayerService = this.config.isDevice ? this.nativeAudioPlayerService : this.webAudioPlayerService;

    this.subtitleLanguageSubscription = this.store.select(getSubtitlesLanguage)
      .pipe(
        take(1)
      )
      .subscribe((subtitlesLanguage) => {
        this.userSettingsLanguage = subtitlesLanguage;
        this.selectedLang = subtitlesLanguage;

        this.addPlayerListeners();

        if (this.controls) {
          this.controls.pipe(takeUntil(this.unsubscribe$)).subscribe((action) => this.parentPlayControl(action));
        }

        this.load().then(() => { this.networkError && !this.hasPopupBefore && this.alertError('network'); });
      });
  }

  ngOnDestroy() {
    this.notificationCloseListener && this.notificationCloseListener.remove();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.closePlayer();
  }

  ngOnChanges(changes) {
    if (changes.brightcove_key) {
      const { brightcove_key } = changes;
      const hasBrightcoveKeyChanges = brightcove_key && brightcove_key.currentValue !== brightcove_key.previousValue && !brightcove_key.firstChange;

      if (!hasBrightcoveKeyChanges) {
        return;
      }

      if (brightcove_key && brightcove_key.currentValue) {
        this.load();
      }
    }
  }

  onSeekStart(): void {
    this.tapticService.impact();
    this.isSeeking = true;
  }

  onSeekEnd() {
    this.tapticService.impact();

    if (this.isSeeking) {
      this.setCurrentTime(this.currentTimeInSeconds);
      this.isSeeking = false;
    }
  }

  onRangeChange(event) {
    this.currentTimeInSeconds = event.target.value;
  }

  async forwardFifteenSec() {
    if(this.playing) {
      await this.audioPlayerService.jumpBy(15);
    }
  }

  async backwardFifteenSec() {
    if(this.playing) {
      this.audioPlayerService.jumpBy(-15);
    }
  }

  play() {
    this.playing ? this.pausePlay() : this.resumePlay();
  }

  async languageChange(event) {
    this.selectedLang = event.detail.value;

    if (this.selectedLang === 'Off') {
      this.store.dispatch(new SetSubtitles(''));
    } else {
      this.store.dispatch(new SetSubtitles(this.selectedLang));
    }

    this.loadSubtitles();
  }

  // We may encounter the code es-419 if during the import process, Spanish (Latin America) is selected instead of Spanish.
  // This has no impact on the displayed subtitles. In both cases, we simply display "Spanish".
  getLanguageName(languageCode: 'es' | 'es-419' | 'en') {
    switch(languageCode) {
      case 'en':
        return 'English';
      case 'es-419':
      case 'es':
        return 'Spanish';
      default:
        return languageCode;
    }
  }

  private async load(): Promise<void> {
    this.updateProgressBar(0);
    let options: { brightcove_key: string; webPlayerElement?: ElementRef; notificationOptions?: AudioNotificationOptions };

    if(this.config.isDevice) {
      // Check if the media is available or if the user is online
      if(!(await BrightcovePlayer.isMediaAvailableLocally({ fileId: this.brightcove_key})).value && this.connectivityService.isOffline()) {
        this.networkError = true;

        return Promise.resolve();
      }

      options = {
        brightcove_key: this.brightcove_key,
        notificationOptions: {
          forwardIncrementMs: 15000,
          rewindIncrementMs: 15000
        }};
    } else {
      options = {
        brightcove_key: this.brightcove_key,
        webPlayerElement: this.webPlayerElement
      };
    }

    await this.audioPlayerService.load(options);

    return this.handleSubtitles();
  }

  private addPlayerListeners() {
    // Try to reload the audio if the user is online again
    this.connectivityService.isConnectedObservable
      .pipe((takeUntil(this.unsubscribe$)))
      // This delay is necessary because in some cases, the networkStatusChange event
      // is called too early and the internet is not yet available for the brightcove plugin
      .pipe(delay(1000))
      .subscribe(connected => {
        if(connected && this.networkError) {
          this.autoplay = true;
          this.networkError = false;
          this.loading = true;
          this.load();
        }
      });

    this.audioPlayerService.position$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(position => {
        if (this.isSeeking) {
          return;
        }
        this.currentTimeInSeconds = position / 1000;
        this.updateProgressBar(this.currentTimeInSeconds);
        this.updateSubtitle(this.currentTimeInSeconds);

        if (this.currentTimeInSeconds > this.MINIMUM_PLAYED_SECS && !this.playedMinimumEmitted) {
          this.playedMinimum.emit();
          this.playedMinimumEmitted = true;
        }
      });

    this.audioPlayerService.playerStatus.pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (status: AudioPlayerStatus) => {
        this.loading = status === AudioPlayerStatus.MEDIA_LOADING || status === AudioPlayerStatus.STARTING;
        this.playing = status === AudioPlayerStatus.RUNNING;

        switch (status) {
          case AudioPlayerStatus.MEDIA_LOADED:
            this.canPlayThrough.emit(true);
            this.autoplay && this.resumePlay();
            break;
          case AudioPlayerStatus.ERROR:
            this.logger.error('BrightcoveAudioPlayerError', `An error occurred with the media ${this.brightcove_key}`);
            this.alertError('technical', PLAYER_ERROR_CODES.TECHNICAL_ERROR);
            break;
          case AudioPlayerStatus.ENDED:
            this.completed.emit();
            break;
        }

        this.cdr.detectChanges();
      });
  }

  private setCurrentTime(newTime): Promise<void> {
    this.currentTimeInSeconds = newTime;

    return this.audioPlayerService.seekTo(newTime);
  }

  private resumePlay() {
    if(this.networkError || this.technicalError) {
      return this.alertError(this.networkError ? 'network' : 'technical');
    }

    this.audioPlayerService.play().catch(reason => {
      this.logger.error('BrightcoveAudioPlayerError', reason, 'Error playing audio');
    });
  }

  private pausePlay() {
    this.audioPlayerService.pause();
  }

  private parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();
      case 'pause':
        return this.pausePlay();
      default:
        return false;
    }
  }

  private updateProgressBar(position) {
    if (position > this.audioPlayerService.getDuration()) {
      position = this.audioPlayerService.getDuration();
    }
    if (this.rangeBar) {
      this.rangeBar.value = position;
    }
    this.audioTotalLength = this.audioPlayerService.getDuration() > 0 ? (this.audioPlayerService.getDuration() / 1000) : 0;
    this.displayTotalLength = getFormattedTimeInMinutesAndSeconds((this.audioTotalLength >= 0) ? this.audioTotalLength : 0);
    this.cdr.detectChanges();
  }

  private alertError(errorType: 'technical' | 'network', errorCode?: PLAYER_ERROR_CODES) {
    errorType === 'technical' ? this.technicalError = true : this.networkError = true;
    this.loading = false;
    this.alertsService.playerError(errorType, errorCode);
    this.cdr.detectChanges();
  }

  private closePlayer() {
    if(this.technicalError || this.networkError || this.closePressed) {
      return;
    }

    this.closePressed = true;

    this.audioPlayerService.stop()
      // Unload before closing modal to avoid error when closing/opening audio lesson too fast
      .then(() => this.audioPlayerService.unload())
      .catch(reason => {
        this.logger.error('BrightcoveAudioPlayerError', 'Error closing audio player', reason);
      });
  }

  private async updateSubtitle(position: number) {
    this.currentSubtitle = this.audioSubtitlesService.getText(this.cues, position);
    this.cdr.detectChanges();
  }

  private async handleSubtitles() {
    // Brightcove subtitle are currently not available if the user is offline event if the media is downloaded
    // This is due to a Brightcove SDK bug. The Brightcove team is currently working on this issue.
    // In the meantime, we hide the subtitle tool if the user is offline.
    if(this.config.isDevice && this.connectivityService.isOffline()) {
      this.connectivityService.isConnectedObservable.pipe(
        filter(connected => connected),
        takeUntil(this.unsubscribe$),
        take(1),
        delay(1000))
        .subscribe(() => this.handleSubtitles());

      return;
    }

    this.audioPlayerService.getLanguages(this.brightcove_key)
      .then(languages => {
        this.languages = languages;
        // Check that the subtitle is available in user settings language
        this.selectedLang = this.languages.includes(this.userSettingsLanguage) ? this.userSettingsLanguage : 'Off';
      })
      .catch(error => {
        this.languages = [];
      })
      .finally(() => {
        this.loadSubtitles();
      });
  }

  private loadSubtitles() {
    if (!this.selectedLang || this.selectedLang === 'Off') {
      this.cues = [];

      return;
    }

    this.loadingSubtitles = true;
    this.audioPlayerService.getTextTracks(this.selectedLang, this.brightcove_key)
      .then(cues => {
        this.cues = cues;
        this.loadingSubtitles = false;
        this.loadedSubtitles = true;
      })
      .catch(error => {
        this.logger.error('BrightcoveAudioPlayerError', 'Error loading subtitles', error);
        this.loadingSubtitles = false;
      });

  }
}
