import { createSelector } from '@ngrx/store';
import { WeightActivity } from '../../normalized/schemas/weight-activity.schema';
import { getUserWeightInfo } from '../../normalized/selectors/program-bootstrap.selectors';
import { getWeightActivity } from '../../normalized/selectors/weight-activity.selectors';

export const getLastWeightActivity = createSelector(
  getWeightActivity,
  (items) => {
    if (items && items.length > 0) {
      const lastItem = items.reduce((accumulator, currentValue) => compareWeightActivity(accumulator, currentValue) < 0 ? accumulator : currentValue, items[0]);

      return lastItem;
    }

    return null;
  }
);

export function compareWeightActivity(weightActivityA: WeightActivity, weightActivityB: WeightActivity) {
  return new Date(weightActivityB.activity_at).getTime() - new Date(weightActivityA.activity_at).getTime();
}

export const getWeightChange = createSelector(
  getLastWeightActivity,
  getUserWeightInfo,
  (lastWeight: WeightActivity, weightInfo) => {
    if (lastWeight && lastWeight.value && weightInfo.value) {
      return {
        value: Math.abs(weightInfo.value - lastWeight.value),
        down: weightInfo.value - lastWeight.value > 0,
        unit: weightInfo.unit
      };
    }

    return {
      ...weightInfo,
      value: 0,
      down: false
    };
  }
);
