import { Injectable, OnDestroy } from '@angular/core';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state.reducer';

import { Md5 } from 'ts-md5';
import { Subject } from 'rxjs';
import { User } from 'src/app/store/normalized/schemas/user.schema';
import { MarketingAnalyticsInterface, UpdateUserDataType } from '../analytics.interface';
import { LoggerService } from 'src/app/services/logger.service';
import { IKochavaTrackerSdk, KochavaTrackerEventType } from './kochava-sdk.interface';
import { SubscriptionEvents } from '../analytics.events';
import { getCurrentUser } from 'src/app/store/normalized/selectors/user.selectors';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class KochavaService implements OnDestroy, MarketingAnalyticsInterface {

  public SERVICE_NAME = 'kochava';
  private userData$ = this.store.select(getCurrentUser); // @todo remove this from this service
  private unsubscribe$ = new Subject();

  constructor(
    private config: ClarityConfig,
    public store: Store<State>,
    private logger: LoggerService
  ) {

  }

  get kochavaTracker(): IKochavaTrackerSdk {
    if (!this.config.isDevice) {
      return;
    }

    const kochavaSdk = window['KochavaTracker']?.instance;

    if (!kochavaSdk) {
      this.logger.error('Kochava SDK not found', console.trace(), 'KochavaService');
    }

    return kochavaSdk;
  }

  public async initialize(): Promise<any> {
    if (!this.kochavaTracker) {
      return;
    }

    this.askAdTrackingPermission();

    this.kochavaTracker.registerAndroidAppGuid(this.config.program.kochava.androidAppGuid);
    this.kochavaTracker.registerIosAppGuid(this.config.program.kochava.iosAppGuid);
    this.kochavaTracker.start();
  }

  public askAdTrackingPermission() {
    this.kochavaTracker.enableIosAtt();
    this.kochavaTracker.setIosAttAuthorizationAutoRequest(true);
  }

  public async resetService(): Promise<any> {}

  public trackSubscription(eventType: SubscriptionEvents, subscription: any = {}, product: any = {}) {
    if (!this.config.isDevice || eventType !== SubscriptionEvents.Purchased) {
      console.log('Kochava skipping trackSubscription');

      return;
    }

    this.userData$ // @todo remove this from this service
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(user => !!user?.id && !!user?.user_id)
      )
      .subscribe((user) => {

        const userId = this.encodeUserId(user);
        this.setIdentityLink(userId);

        if (!product?.priceMicros || !product?.currency || !product?.id) {
          this.logger.error('Kochava trackSubscription not enough data', { subscription, product });

          return;
        }

        const koEvent = this.kochavaTracker.buildEventWithEventType(KochavaTrackerEventType.Subscribe);
        koEvent.setPrice(product.priceMicros / 1000000);
        koEvent.setCurrency(product.currency);
        koEvent.setName(product.id);
        koEvent.setUserId(userId);

        // koEvent.setAndroidGooglePlayReceipt(receiptData, receiptDataSignature); // Android Only
        // koEvent.setIosAppStoreReceipt(appStoreReceiptBase64EncodedString); // iOS Only

        koEvent.send();
      });
  }

  public async registerUser(data: UpdateUserDataType) {
    // @todo move registrationComplete() code to here after the
    // registerUser() flow is fixed. Currently, registerUser() is being called
    // after every app_launch and several times in userLogin

    if (!this.kochavaTracker) {
      return;
    }

    const userId = this.encodeUserId(data.user);
    this.setIdentityLink(userId);

    this.kochavaTracker.sendEvent('Login');
  }

  public registrationComplete({ user }: { user: User }) {
    if (!this.kochavaTracker) {
      return;
    }

    const userId = this.encodeUserId(user);
    this.setIdentityLink(userId);

    const koEvent = this.kochavaTracker.buildEventWithEventType(KochavaTrackerEventType.RegistrationComplete);
    koEvent.send();
  }

  private encodeUserId(user: User) {
    // we cannot sent plain user ids to Kochava, so we'll md5(userId_userProgramId)
    const hash = Md5.hashStr(
      `${user.user_id.toString()}_${user.id.toString()}`
    )
      .toString();

    // console.log(`Hashed Kochava user id: ${user.user_id.toString()}_${user.id.toString()}`, hash);

    return hash;
  }

  private setIdentityLink(userId: string) {
    this.kochavaTracker.registerIdentityLink('User ID', userId);
  }

  // @see https://support.kochava.com/sdk-integration/adobe-analytics-integration/
  public setAdobeExperienceCloudID(ECID: string) {
    this.kochavaTracker.registerIdentityLink('experiencecloudid', ECID);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
