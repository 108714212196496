import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';
import { MinutesActivity, minutesActivitySchema } from '../schemas/minutes-activity.schema';
import { differenceInSeconds, format } from 'date-fns';
import { IntegrationSource } from '../../session/models/integration.model';

const minutesActivitySchemaSelector = createSchemaSelectors<MinutesActivity>(minutesActivitySchema);

export const getMinutesActivity = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) => minutesActivities ? minutesActivities
    .sort((a, b) => differenceInSeconds(new Date(b.activity_at), new Date(a.activity_at))) : []
);

export const getTodayManualMinutesActivity = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) => getFilteredTodayActivity(minutesActivities, [IntegrationSource.manual])
);

export const getOfflineManualActivity = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) =>
    minutesActivities ? minutesActivities
      .filter(minutesActivity => minutesActivity.source === IntegrationSource.manual && minutesActivity.localState?.status === 'offline')
      : []
);

export const getFitbitTodayManualMinutesActivitySum = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) => getSumFilteredTodayActivity(minutesActivities, [IntegrationSource.fitbit])
);

export const getHealthkitTodayManualMinutesActivitySum = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) => getSumFilteredTodayActivity(minutesActivities, [IntegrationSource.healthkit])
);

export const getGooglefitTodayManualMinutesActivitySum = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) => getSumFilteredTodayActivity(minutesActivities, [IntegrationSource.googlefit])
);

export const getTodaysMinutesActivitySum = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) =>
    getSumFilteredTodayActivity(minutesActivities, [])
);

// Get the activity history by day, except for the current day. Merge activities by day
export const getDailyActivity = createSelector(
  minutesActivitySchemaSelector.getEntities,
  (minutesActivities) => {
    const todayAtMidnight = new Date();
    todayAtMidnight.setHours(0,0,0,0);

    return !minutesActivities || minutesActivities?.length === 0 ? [] :
      minutesActivities
        .filter(minutesActivity => new Date(minutesActivity?.activity_at)?.getTime() <= todayAtMidnight.getTime())
        // remove offline deleted entries
        .filter(minutesActivity => !(minutesActivity.localState?.action === 'delete' && ['offline','pending'].includes(minutesActivity.localState?.status)))
        .sort((a, b) => differenceInSeconds(new Date(b.activity_at), new Date(a.activity_at)))
        .reduce((accumulator, currentValue) => {
          const today = format(new Date(currentValue.activity_at), 'yyyy-MM-dd');
          if(accumulator[today] === undefined) {
            accumulator[today] = [];
          }

          accumulator[today].unshift(currentValue);

          return accumulator;
        }, {});
  }
);

const getFilteredTodayActivity  = (minutesActivities: MinutesActivity[], filters: Array<String>): MinutesActivity[] => {
  const todayAtMidnight = new Date();
  todayAtMidnight.setHours(0,0,0,0);
  const todayAtEndOfDay = new Date();
  todayAtEndOfDay.setHours(23,59,59,999);

  return !minutesActivities || minutesActivities?.length === 0 ? [] : minutesActivities
    .filter(minutesActivity =>
      new Date(minutesActivity?.activity_at)?.getTime() > todayAtMidnight.getTime()
      && new Date(minutesActivity?.activity_at)?.getTime() < todayAtEndOfDay.getTime())
  // remove offline deleted entries
    .filter(minutesActivity => !(minutesActivity.localState?.action === 'delete' && ['offline','pending'].includes(minutesActivity.localState?.status)))
    .filter(minutesActivity => filters.length > 0 ? filters.includes(minutesActivity.source) : true);
};

const getSumFilteredTodayActivity = (minutesActivities: MinutesActivity[], filters: Array<String>): number => {
  const filteredTodayActivity = getFilteredTodayActivity(minutesActivities, filters);

  return filteredTodayActivity.length === 0 ? 0 : filteredTodayActivity
    .reduce((accumulator, currentValue) => accumulator + currentValue?.minutes, 0);
};
