import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SettingsComponent } from '../settings.component';
import {
  getAllowHealthFitIntegration, getWeightUnit } from '../../../../store/normalized/selectors/user.selectors';
import { State } from '../../../../store/state.reducer';
import { getUserWeightInfo } from 'src/app/store/normalized/selectors/program-bootstrap.selectors';
import { getLastWeightActivity, getWeightChange } from 'src/app/store/session/selectors/weight-activities.selectors';
import { getWeightActivity } from 'src/app/store/normalized/selectors/weight-activity.selectors';
import { AddManualWeightActivity } from 'src/app/store/session/actions/integrations.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-account-weight-track-ern',
  styleUrls: ['weight-track.component.scss'],
  template: `
    <div class="account-weight-container">
      <ion-row class="weight-stats">
        <ion-col class="weight-tracker">
          <cl-weight-track-block
            [weightTrackingInfo]="weightInfo$ | async"
            [weightTitle]="'weight_tracking.start' | translate">
          </cl-weight-track-block>
        </ion-col>

        <ion-col class="weight-tracker big">
          <div *ngIf="(lastWeight$ | async) as lastWeightData">
            <cl-weight-track-block
              [weightTrackingInfo]="lastWeightData"
              [big]="true"
              [weightTitle]="'weight_tracking.current' | translate">
            </cl-weight-track-block>
          </div>
          <div *ngIf="!(lastWeight$ | async)">
            <cl-weight-track-block
              [weightTrackingInfo]="weightInfo$ | async"
              [big]="true"
              [weightTitle]="'weight_tracking.current' | translate">
            </cl-weight-track-block>
          </div>
        </ion-col>

        <ion-col class="weight-tracker">
          <cl-weight-track-block
            [weightTrackingInfo]="weightChange$ | async"
            [weightTitle]="'weight_tracking.change' | translate">
          </cl-weight-track-block>
        </ion-col>
      </ion-row>

      <cl-add-manual-weight
        [weightUnit]="(getWeightUnit$ | async) || 'lb'"
        [rules]="(allowHealthFitIntegration$ | async) ? 'healthfit': 'default'"
        (addManualWeight)="addManualWeight($event)" >
      </cl-add-manual-weight>

      <div *ngIf="(weightActivities$ | async)?.length  > 0" class="weight-track-info">
        <div class="weight-entries-title">
          {{ 'weight_tracking.entries' | translate }}
        </div>

        <div class="weight-track-list">
          <div *ngFor="let activity of (weightActivities$ | async) | slice:0:shownWeightActivities" class="weight-point-container">
            <cl-weight-activity-card
              [activity]="activity"
              [rules]="(allowHealthFitIntegration$ | async) ? 'healthfit': 'default'"
            >
            </cl-weight-activity-card>
          </div>
        </div>
      </div>

      <div *ngIf="(weightActivities$ | async)?.length === 0" class="no-weight">
        {{ 'weight_tracking.no_weight' | translate }}
      </div>

      <ion-row
        *ngIf="(weightActivities$ | async)?.length > shownWeightActivities"
        class="action-button"
        (click)="showMoreWeightActivities()">
        <ion-col class="ion-text-center">
          <div class="see-more">
            {{'weight_tracking.see_more' | translate}}
          </div>
        </ion-col>
      </ion-row>
    </div>`
})

export class WeightTrackERNComponent implements SettingsComponent {
  allowHealthFitIntegration$ = this.store.select(getAllowHealthFitIntegration);
  getWeightUnit$ = this.store.select(getWeightUnit);
  weightInfo$ = this.store.select(getUserWeightInfo);
  lastWeight$ = this.store.select(getLastWeightActivity);
  weightChange$ = this.store.select(getWeightChange);
  weightActivities$ = this.store.select(getWeightActivity);
  shownWeightActivities = 7;

  constructor(private store: Store<State>) { }

  showMoreWeightActivities() {
    this.shownWeightActivities += 7;
  }

  addManualWeight(data: { value: number; weightUnit: string; date: Date }) {
    this.store.dispatch(new AddManualWeightActivity(data.value, data.weightUnit, data.date));
  }
}
