import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

import { TranslateModule } from '@ngx-translate/core';

import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { storeDevtoolsModule } from '../environments/build-specifics/store-devtools-module';

import { getMetaReducers } from './store/meta.reducers';

import { AppComponentModule } from './app.component.module';
import { AppComponent } from './app.component';
import { ClarityConfig } from './config/clarity.config';
import { HttpProvider, HttpProviderFactory } from './providers/http/http.provider';
import { IridiumAuthorizationInterceptor } from './providers/http/interceptors/iridium-authorization.interceptor';
import { ClarionAuthorizationInterceptor } from './providers/http/interceptors/clarion-authorization.interceptor';
import { HeadersInterceptor } from './providers/http/interceptors/headers.interceptor';
import { ApiUrlInterceptor } from './providers/http/interceptors/api-url.interceptor';

import { reducer as fromStoreReducer } from './store/state.reducer';

import { OfflineSyncEffects } from './store/offline/offline.effects';
import { ClarityErrorHandler } from './config/error.handler';
import { translateConfig } from './config/translate.config';

import { LessonsPageModule } from './pages/lessons/lessons.module';
import { AccountPageModule } from './pages/account/account.module';
import { CravingToolModule } from './pages/craving-tool/craving-tool.module';
import { CravingToolModule as CravingToolCtqModule } from './pages/craving-tool-ctq/craving-tool-ctq.module';
import { AuthGuard } from './guards/auth.guard';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { InterstitialPageModule } from './pages/interstitial/interstitial.module';
import { RestartProgramPageModule } from './pages/restart-program/restart-program.module';
import { UpgradePageModule } from './pages/upgrade/upgrade.module';
import { RainExercisePageModule } from './pages/rain-exercise/rain-exercise.module';
import { BreathExercisePageModule } from './pages/breathe-exercise/breathe-exercise.module';
import { LicensePageModule } from './pages/license/license.module';
import { NewCustomWeekPageModule } from './pages/new-custom-week/new-custom-week.module';
import { NewThemeWeekPageModule } from './pages/new-theme-week/new-theme-week.module';
import { WizardPageModule } from './pages/wizard/wizard.module';
import { SelectThemePageModule } from './pages/select-theme/select-theme.module';
import { CustomPathPageModule } from './pages/custom-path/custom-path.module';
import { SubscribePageModule } from './pages/subscribe/subscribe.module';
import { NextStepsPageModule } from './pages/next-steps/next-steps.module';
import { PlayPageModule } from './pages/play/play.module';
import { LoginEmailPageModule } from './pages/login-email/login-email.module';
import { MyStatsModule } from './pages/my-stats/my-stats.module';
import { GearsReviewPageModule } from './pages/gears-review/gears-review.module';
import { AppRoutingModule } from './app-routing.module';
import { OnboardingPageModule } from './pages/onboarding/onboarding.module';
import { DebugPageModule } from './pages/debug/debug.module';
import { PostPageModule } from './pages/post/post.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileCompletionModule } from './pages/profile-completion/profile-completion.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NrtPageModule } from './pages/nrt/nrt.module';
import { LoginGuard } from './guards/login.guard';
import { AccountCompletedGuard } from './guards/account-completed.guard';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { AccountSetupPageModule } from './pages/account-setup/account-setup.module';
import { MyCoachCtqPageModule } from './pages/my-coach-ctq/my-coach-ctq.module';
import { MyCoachConversationPageModule } from './pages/my-coach-conversation/my-coach-conversation.module';
import { InappMessageModule } from './pages/inapp-message/inapp-message.module';
import { WelcomeVideoPageModule } from './pages/welcome-video/welcome-video.module';
import { MediaFilesInterceptor } from './providers/http/interceptors/media-files.interceptor';
import { UserProfileModule } from './pages/user-profile/user-profile.module';
import { EventsService } from './services/events.service';
import { Facebook } from '@ionic-native/facebook/ngx';
import { MyCoachBioPageModule } from './pages/my-coach-bio/my-coach-bio.module';
import { Mixpanel, MixpanelPeople } from '@ionic-native/mixpanel/ngx';
import { AccountDeletionModule } from './pages/account-deletion/account-deletion.module';
import { AccountDeletionConfirmationModule } from './pages/account-deletion-confirmation/account-deletion-confirmation.module';

export const APP_MODULE_IMPORTS = [
  AppComponentModule,
  AppRoutingModule,
  BrowserAnimationsModule,

  // ngrx
  StoreModule.forRoot(fromStoreReducer, {
    initialState: {
      hydrated: false
    },
    runtimeChecks: {
      // @todo change all to true to enforce best practices
      strictStateImmutability: false,
      strictActionImmutability: false,
      strictStateSerializability: false,
      strictActionSerializability: false,
      strictActionWithinNgZone: false,
      strictActionTypeUniqueness: false
    }
  }),
  EffectsModule.forRoot([OfflineSyncEffects]),
  ...storeDevtoolsModule,

  // Angular modules
  BrowserModule,
  HttpClientModule,

  // External modules
  ImageCropperModule,

  // translations
  TranslateModule.forRoot(translateConfig),

  // CLARITY modules which should be pre-loaded - lazy loading causes bad UX
  AccountPageModule,
  AccountSetupPageModule,
  BreathExercisePageModule,
  PlayPageModule,
  CravingToolModule,
  CravingToolCtqModule,
  DebugPageModule,
  CustomPathPageModule,
  InterstitialPageModule,
  LessonsPageModule,
  LicensePageModule,
  LoginEmailPageModule,
  GearsReviewPageModule,
  MyCoachCtqPageModule,
  MyCoachConversationPageModule,
  MyCoachBioPageModule,
  MyStatsModule,
  NewCustomWeekPageModule,
  NewThemeWeekPageModule,
  NextStepsPageModule,
  NrtPageModule,
  OnboardingPageModule,
  PostPageModule,
  ProfileCompletionModule,
  InappMessageModule,
  RainExercisePageModule,
  WelcomeVideoPageModule,
  RestartProgramPageModule,
  SelectThemePageModule,
  SubscribePageModule,
  UpgradePageModule,
  UserProfileModule,
  WizardPageModule,
  AccountDeletionModule,
  AccountDeletionConfirmationModule
  // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
];

export const APP_MODULE_PROVIDERS = [
  // TODO: migrate https://cordova.apache.org/news/2017/11/20/migrate-from-cordova-globalization-plugin.html
  // eslint-disable-next-line import/no-deprecated
  Globalization,
  Deeplinks,
  BackgroundMode,
  AuthGuard,
  LoginGuard,
  AccountCompletedGuard,
  // TODO: Enable custom error handler - figure out if logged file and line numbers are correct
  {provide: ErrorHandler, useClass: ClarityErrorHandler},
  {provide: HttpProvider, useFactory: HttpProviderFactory, deps: [HttpClient, EventsService]},
  {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ClarionAuthorizationInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: IridiumAuthorizationInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: MediaFilesInterceptor, multi: true},
  {provide: USER_PROVIDED_META_REDUCERS, deps: [NgZone], useFactory: getMetaReducers},
  ClarityConfig,
  StatusBar,
  SplashScreen,
  MobileAccessibility,
  Facebook,
  Mixpanel,
  MixpanelPeople
];

@NgModule({
  imports: [
    ...APP_MODULE_IMPORTS
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    ...APP_MODULE_PROVIDERS
  ]
})
// This AppModule class is only kept to still keep the test coverage reports. It's not used anywhere else.
// TODO CLARITY-1187 refactor the test coverage reports and remove this AppModule to make the code more understandable.
export class AppModule {
}
