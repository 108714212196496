<ion-header [class.terms-header]="!termsAgreed">
  <cl-header-nav-buttons
    *ngIf="termsAgreed"
    class="page-header"
    color="blank"
    [backButton]="false"
    (closeClick)="closeModal()"
    [title]="'account_menu.community.avatar_modal_title' | translate ">
  </cl-header-nav-buttons>

  <cl-header-nav-buttons
    *ngIf="!termsAgreed"
    class="page-header"
    color="blank"
    [backButton]="false"
    (closeClick)="closeModal()"
    [title]="'account_menu.community.terms_of_service' | translate ">
  </cl-header-nav-buttons>
</ion-header>

<ion-content>
  <div class="community-terms lateral-padding" *ngIf="!termsAgreed">
    <p>{{'account_menu.community.community_terms' | translate : {program: this.config.program.name} }}</p>
    <p class="terms-link" [innerHTML]="'account_menu.community.terms_link' | translate" (click)="onTermsClick()"></p>
  </div>

  <div class="profile-completion-container lateral-padding" *ngIf="termsAgreed">
    <form [formGroup]="profileForm" autocomplete="off" (submit)="profileCompletion()">
      <div class="avatar-wrapper">

        <div *ngIf="imageLoading">
          <ion-spinner color="primary"></ion-spinner>
        </div>

        <div class="avatars" *ngIf="!imageEditing && !imageLoading">
          <ion-avatar *ngIf="!croppedImage">
            <img [src]="(currentUserProfile$ | async)?.avatar || 'assets/imgs/generic-avatar.png'"/>
          </ion-avatar>

          <ion-avatar *ngIf="croppedImage" class="avatar-preview">
            <img [src]="croppedImage"/>
          </ion-avatar>
          <div class="image-actions">
            <div *ngIf="croppedImage" class="image-action-icon-ctr" (click)="reset()">
              <ion-icon name="crop"></ion-icon>
            </div>
            <label for="profileAvatarField" class="image-action-icon-ctr">
              <ion-icon name="camera"></ion-icon>
            </label>
          </div>

        </div>
        <div *ngIf="imageEditing && !imageLoading">
          <image-cropper
              (cropperReady)="cropperReady($event)"
              (imageCropped)="imageCropped($event)"
              [cropper]="cropperCoordinates"
              [imageURL]="imageUrl"
              [maintainAspectRatio]="true"
              [roundCropper]="true"
              aspectRatio="1"
              format="jpeg"
          ></image-cropper>
          <ion-row>
            <ion-col class="ion-text-center" auto>
              <ion-button (click)="cancelEdition()" class="action-btn">
                <ion-icon slot="start" name="close"></ion-icon>
                {{'social.profile.cancelAvatarEdit' | translate }}
              </ion-button>
              <ion-button (click)="saveEdition()" color="action" class="action-btn">
                <ion-icon slot="start" name="checkmark"></ion-icon>
                {{'social.profile.validateAvatarEdit' | translate }}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </div>

      <ion-item class="profile-field">
        <ion-label position="stacked">{{'social.profile.username' | translate }}</ion-label>
        <ion-input formControlName="username"></ion-input>
      </ion-item>

      <input id="profileAvatarField" #profileAvatarField style="display: none;" type="file"
            (change)="changedAvatar($event)"
            accept="image/*"/>
      <ion-input type="hidden" formControlName="avatar"></ion-input>

      <div class="quit-date-wrapper">
        <ion-item class="profile-field">
          <ion-label position="stacked">{{'auth.free_since_text' | translate}}</ion-label>
          <ion-item class="quitting-date" id="profile-completion-quitting-date">
            <ion-input>{{ quittingDate.value }}</ion-input>
          </ion-item>

          <ion-modal class="date-time-picker" trigger="profile-completion-quitting-date">
            <ng-template>
              <ion-content>
                <ion-datetime
                  (ionChange)="setQuittingDate($event)"
                  [value]="quittingDate.value"
                  [showDefaultButtons]="true"
                  presentation="date"
                  [max]="maxQuitDate">
                </ion-datetime>
              </ion-content>
            </ng-template>
          </ion-modal>
        </ion-item>

        <ion-button fill="clear" (click)="resetQuitDate()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </div>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{ 'auth.save_profile' | translate }}"
            [canClick]="profileForm && profileForm.valid">
          </cl-action-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <p class="disclaimer-text">{{'community.profile_disclaimer' | translate}}</p>
        </ion-col>
      </ion-row>
    </form>
  </div>
  <!-- Canvas used to resize large images to not use too much memory -->
  <canvas #bufferCanvas width="800" height="600" style="display: none;"></canvas>

</ion-content>

<ion-footer *ngIf="!termsAgreed">
  <div class="terms-controls lateral-padding">
    <ion-row>
      <ion-col>
        <cl-prev-button
          [label]="'common.back' | translate"
          (action)="onClose()">>
        </cl-prev-button>
      </ion-col>

      <ion-col>
        <cl-next-button
          [label]="'common.i_agree' | translate"
          [canClick]="true"
          (action)="onAgree()">
        </cl-next-button>
      </ion-col>
    </ion-row>
  </div>
</ion-footer>

