import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SettingsComponent } from '../settings.component';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { slideInOut } from '@mindsciences/utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-account-weight',
  animations: [slideInOut],
  template: `
    <div [@state]="visibility" (@state.done)="animationDone($event)">
      <ng-container *ngIf="config.programDPPorWL(); else defaultWeightTrack">
        <cl-account-weight-track-dpp></cl-account-weight-track-dpp>
      </ng-container>

      <ng-template #defaultWeightTrack >
        <cl-account-weight-track-ern></cl-account-weight-track-ern>
      </ng-template>
    </div>
  `
})

export class WeightTrackComponent implements SettingsComponent {
  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  constructor(public config: ClarityConfig) { }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
