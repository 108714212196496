import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { WeightActivity } from 'src/app/store/normalized/schemas/weight-activity.schema';

export interface WeightTrackingInfo extends Pick<WeightActivity, 'value' | 'unit'> {
  down?: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-weight-track-block',
  styleUrls: ['weight-track-block.component.scss'],
  template: `
    <div>
      <span class="block-title">
        {{weightTitle}}
      </span>
      <div class="with-shadow">
        <img (click)="openInfoModal()" *ngIf="showInfoIcon" class="info-icon" src="assets/imgs/info.svg">
        <div class="weight-number" [ngClass]="{'big': big}">
          <ion-icon
            *ngIf="weightTrackingInfo.down !== undefined && weightTrackingInfo.value !== 0"
            class="inline-weight-change"
            name="arrow-{{weightTrackingInfo.down ? 'down' : 'up' }}">
          </ion-icon>
          {{weightTrackingInfo.value | number:'1.1-1'}}
        </div>
        <div class="weight-unit">
          {{weightTrackingInfo | weightUnit }}
        </div>
      </div>
    </div>
  `
})

export class WeightTrackBlockComponent {
  @Input() weightTrackingInfo: WeightTrackingInfo;
  @Input() big = false;
  @Input() weightTitle: string;
  @Input() showInfoIcon = false;

  constructor(private onboardingService: OnboardingService) { }

  openInfoModal() {
    this.onboardingService.checkShowingOnboarding({
      type: 'dppwlWeightGoal'
    });
  }
}
