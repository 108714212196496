import { NotificationsService } from './notification.service';
import { NgModule } from '@angular/core';
// import { HttpModule } from '@angular/http';

import { Keyboard } from '@ionic-native/keyboard/ngx';
import { HTTP } from '@ionic-native/http/ngx';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { Md5Plugin } from './files/md5.plugin';

// providers
import { AuthProvider } from '../providers/auth.provider';
import { ExercisesProvider } from '../providers/exercises.provider';
import { ProgramProvider } from '../providers/program.provider';
import { RemindersProvider } from '../providers/reminders.provider';
import { SubscriptionsProvider } from '../providers/subscriptions/subscriptions.provider';
import { ThemeWeeksProvider } from '../providers/theme-weeks.provider';
import { UserRemindersProvider } from '../providers/user-reminders.provider';
import { UserProvider } from '../providers/user.provider';
import { UserBootstrapProvider } from '../providers/user-bootstrap.provider';
import { UserProgressProvider } from '../providers/user-progress.provider';
import { GoalsProvider } from '../providers/goals.provider';
import { CheckinsProvider } from '../providers/wizards/checkins.provider';
import { ListItemsProvider } from '../providers/list-items.provider';
import { UserProgramProvider } from '../providers/user-program.provider';
import { UserActivitiesProvider } from '../providers/user-activities.provider';
import { SymptomsProvider } from '../providers/symptoms.provider';
import { StressTestProvider } from '../providers/wizards/stress-test.provider';
import { CravingToolProvider } from '../providers/wizards/craving-tool.provider';
import { StressMeterProvider } from '../providers/wizards/stress-meters.provider';
import { WorryToolProvider } from '../providers/wizards/worry-tool.provider';
import { CommunityProvider } from '../providers/community.provider';
import { UserWeeksProvider } from '../providers/user-weeks.provider';
import { UserProgramSessionProvider } from '../providers/user-program-session.provider';
import { AwarenessQuizzesProvider } from '../providers/awareness-quizzes.provider';
import { AnxietyQuizzesProvider } from '../providers/anxiety-quizzes.provider';
import { CigCountProvider } from '../providers/cig-count.provider';
import { OfflineHttpProvider } from '../providers/http/offline-http.provider';
import { SubtitlesProvider } from '../providers/subtitles.provider';
import { ReleasesProvider } from '../providers/releases.provider';
import { ConnectedApplicationsProvider } from '../providers/connected-applications.provider';

// services
import { AnalyticsService } from './analytics/analytics.service';
import { ConnectivityService } from './connectivity.service';
// import { SimpleStorageService } from './storage/simple-storage.service';
import { LoggerService } from './logger.service';
import { ToastService } from './toast.service';
import { TrackerService } from './tracker.service';
import { LoadingService } from './loading.service';
import { AlertsService } from './alerts.service';
import { SubscriptionsService } from './subscriptions/subscriptions.service';
import { OnboardingService } from './onboarding.service';
import { Md5Service } from './files/md5.service';
import { DownloadService } from './files/download.service';
import { FileService } from './files/file.service';
import { CheckinsService } from './wizards/checkins.service';
import { StressMetersService } from './wizards/stress-meters.service';
import { StressTestService } from './wizards/stress-test.service';
import { StressTestUAService } from './wizards/stress-test-ua.service';
import { ActionCableService } from './actioncable/action-cable.service';
import { UserDataSyncChannelService } from './actioncable/user-data-sync-channel.service';
import { RateService } from './rate.service';
import { OfflineQueueService } from './offline-queue.service';
import { CravingToolService } from './wizards/craving-tool.service';
import { ReleaseService } from './release.service';
import { ScriptLoaderService } from './script-loader.service';
import { IntegrationsService } from './integrations.service';
import { BrightcoveWebAudioPlayerService } from './brightcove/brightcove-web-audio-player.service';
import { TapticService } from './taptic.service';
import { BrightcoveNativePlayerLoaderService } from './brightcove/brightcove-native-player-loader.service';
import { BrightcoveNativeAudioPlayerService } from './brightcove/brightcove-native-audio-player.service';
import { GetMobileAppService } from './get-mobile-app.service';
import { AudioSubtitlesService } from 'src/app/services/brightcove/audio-subtitles.service';

// store
import { persistentReducers } from '../store/persistent/persistent.reducers';
import { persistentEffects, sessionEffects } from '../store/session/effects/index';
import { sessionReducers } from '../store/session/session.reducers';
import { sensitiveReducers } from '../store/sensitive/sensitive.reducers';
import { BrowserService } from './browser.service';
import { TimeService } from './time.service';
import { ResumeService } from './resume.service';
import { EventsService } from './events.service';
import { DeeplinksService } from './deeplinks.service';
import { ProgramDaysProvider } from '../providers/program-days.provider';
import { SyncResolutionService } from './sync-resolution.service';
import { IRootService } from './files/iroot.service';
import { IRootPlugin } from './files/iroot.plugin';

export const CORE_MODULE_IMPORTS = [
  // ngrx
  StoreModule.forFeature('sensitive', sensitiveReducers),
  StoreModule.forFeature('session', sessionReducers),
  StoreModule.forFeature('persistent', persistentReducers),
  EffectsModule.forFeature(sessionEffects),
  EffectsModule.forFeature(persistentEffects)
];

export const CORE_MODULE_PROVIDERS = [
  // Ionic Native
  HTTP,
  Md5Plugin,
  Keyboard,
  BrowserService,
  IRootPlugin,
  // Services
  AnalyticsService,
  ConnectivityService,
  LoadingService,
  LoggerService,
  AlertsService,
  // SimpleStorageService,
  ToastService,
  TrackerService,
  DownloadService,
  FileService,
  Md5Service,
  CheckinsService,
  StressMetersService,
  StressTestService,
  StressTestUAService,
  SubscriptionsService,
  NotificationsService,
  ActionCableService,
  UserDataSyncChannelService,
  OnboardingService,
  OfflineQueueService,
  RateService,
  ReleaseService,
  ScriptLoaderService,
  TimeService,
  ResumeService,
  IntegrationsService,
  EventsService,
  DeeplinksService,
  SyncResolutionService,
  IRootService,
  BrightcoveWebAudioPlayerService,
  TapticService,
  BrightcoveNativePlayerLoaderService,
  BrightcoveNativeAudioPlayerService,
  GetMobileAppService,
  AudioSubtitlesService,
  // Providers
  OfflineHttpProvider,
  AuthProvider,
  CheckinsProvider,
  CravingToolProvider,
  CravingToolService,
  StressTestProvider,
  StressMeterProvider,
  WorryToolProvider,
  ExercisesProvider,
  ListItemsProvider,
  UserActivitiesProvider,
  UserProgramSessionProvider,
  ProgramProvider,
  ProgramDaysProvider,
  RemindersProvider,
  SubscriptionsProvider,
  ThemeWeeksProvider,
  SymptomsProvider,
  UserRemindersProvider,
  UserProvider,
  UserProgramProvider,
  UserBootstrapProvider,
  UserProgressProvider,
  UserWeeksProvider,
  GoalsProvider,
  CommunityProvider,
  AwarenessQuizzesProvider,
  AnxietyQuizzesProvider,
  CigCountProvider,
  CommunityProvider,
  SubtitlesProvider,
  ConnectedApplicationsProvider,
  ReleasesProvider
];


@NgModule({
  imports: [
    ...CORE_MODULE_IMPORTS
  ],
  providers: [
    ...CORE_MODULE_PROVIDERS
  ],
  declarations: [
  ],
  exports: [
  ]
})

export class CoreModule {

}
