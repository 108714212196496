import { Pipe, PipeTransform } from '@angular/core';
import { WeightTrackingInfo } from '../pages/account/components/weight-track/weight-track-block.component';

@Pipe({
  name: 'weightUnit'
})
export class WeightUnitPipe implements PipeTransform {

  wordCapitalize(word: string) {
    const wordStart = word
      .charAt(0)
      .toUpperCase();
    const wordEnd = word
      .slice(1)
      .toLowerCase();

    return wordStart + wordEnd;
  }

  getWeightUnitWithPlural(weightActivity: WeightTrackingInfo) {
    const { value, unit } = weightActivity;
    const weightUnitString = this.wordCapitalize(unit);
    const weightAsNumber = +value;
    const pluralPrefix = weightAsNumber === 1 ? '' : 's';

    return `${weightUnitString}${pluralPrefix}`;
  }

  transform(weightActivity: WeightTrackingInfo): string {
    return this.getWeightUnitWithPlural(weightActivity);
  }

}
