import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { AlertController, NavController } from '@ionic/angular';
import { AddManualMinutesActivity } from '../../../store/session/actions/integrations.actions';
import {
  getDailyActivity,
  getTodayManualMinutesActivity,
  getTodaysMinutesActivitySum
} from '../../../store/normalized/selectors/minutes-activity.selectors';
import { IntegrationSource } from '../../../store/session/models/integration.model';
import {
  getShowInformationDialog,
  isFitbitConnected,
  isGoogleFitConnected,
  isHealthKitConnected, isIntegrationConnected
} from '../../../store/session/selectors/integrations.selectors';
import { IntegrationInformation } from '../../../components/integrations-status-modal.component';
import * as integrationsActions from '../../../store/session/actions/integrations.actions';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { format } from 'date-fns';
import { getCurrentUserProgram, getIsTrackerRedemptionEnabled } from '../../../store/normalized/selectors/user.selectors';
import { slideInOut } from '@mindsciences/utils';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { canOrderFitbitTracker } from 'src/app/store/session/selectors/program.selectors';
import { SessionState } from 'src/app/store/session';

@Component({
  selector: 'cl-account-activity',
  styleUrls: ['activity.component.scss'],
  animations: [slideInOut],
  template: `
    <div [@state]="visibility"
         (@state.done)="animationDone($event)">
      <div *ngIf="config.programDPPorWL() && (isTrackerRedemptionEnabled$ | async)" class="fitbit-tracker">
        <ng-container *ngIf="canOrderFitbitTracker$ | async; then fitbitUnlocked; else fitbitLocked"></ng-container>

        <ng-template #fitbitLocked>
          <div class="locked-title">{{'my_activity.order_fitbit_tracker' | translate}}</div>
          <div class="locked-description-container">
            <img class="tracker-img" src="assets/imgs/fitbit-tracker.png" alt="" />
            <div class="locked-description">
              {{'my_activity.order_fitbit_tracker_condition' | translate }}
            </div>
          </div>
        </ng-template>
        <ng-template #fitbitUnlocked>
          <div class="unlocked-description-container">
            <img class="tracker-img" src="assets/imgs/fitbit-tracker.png" alt="" />
            <div class="get-today">
              <div class="unlocked-description">{{'my_activity.free_tracker_waiting' | translate}}</div>
              <ion-button color="light" (click)="getFitbitTracker()" expand="block" >
                <span class="text">{{'my_activity.get_today' | translate}}</span>
              </ion-button>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="activity">
        <div class="activity-section">
          <ion-grid class="today-grid">
            <ion-row class="ion-align-items-center">
              <ion-col class="ion-text-left main-title" size="5">
                {{'my_activity.today' | translate}}
              </ion-col>
              <ion-col class="ion-text-right date" size="7">{{today}}</ion-col>
            </ion-row>
          </ion-grid>

          <div class="activity-value">
            <div class="unit">{{ 'my_activity.activity_today' | translate}}</div>
            <div class="value">{{ getTodaysMinutesActivitySum$ | async }}
              {{ ((getTodaysMinutesActivitySum$ | async) === 1 ? 'my_activity.minute': 'my_activity.minutes') | translate }}</div>
          </div>

          <div class="connected-device" *ngIf="integrationConnected$ | async">{{ 'my_activity.connected_device' | translate }}
            <img  src="assets/icon/information.svg" alt="" (click)="openConnectedDeviceModal()"/>
          </div>

          <cl-integration-activity-card
            *ngIf="isFitBitConnected$ | async"
            [integration]="integrationSource.fitbit"
            [today]="true">
          </cl-integration-activity-card>
          <cl-integration-activity-card
            *ngIf="isGoogleFitConnected$ | async"
            [integration]="integrationSource.googlefit"
            [today]="true">
          </cl-integration-activity-card>
          <cl-integration-activity-card
            *ngIf="isHealthKitConnected$ | async"
            [integration]="integrationSource.healthkit"
            [today]="true">
          </cl-integration-activity-card>



          <div class="manual-entry-button">
            <cl-action-button
              class=""
              (click)="addMinutesMannualy()"
              [label]="'my_activity.add_manual_entry' | translate"
              [canClick]="true">
            </cl-action-button>
          </div>

          <div class="daily-activity-details" *ngIf="(getTodayManualMinutesActivity$ | async)?.length > 0">
            <cl-minutes-activity-card
              *ngFor="let minutesActivity of (getTodayManualMinutesActivity$ | async) | slice:0:shownMinutesActivities"
              [activity]="minutesActivity">
            </cl-minutes-activity-card>

            <ion-row
              class="action-button"
               *ngIf="(getTodayManualMinutesActivity$ | async)?.length > shownMinutesActivities"
               (click)="showMoreMinutesActivities()">
              <ion-col class="ion-text-center" >
                <ion-button
                  class="dots"
                  color="white"
                  size="small"
                  >
                  ...
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>
      <div class="daily-minutes-section" *ngIf="getDailyKeys?.length > 0">
        <div class="title">{{'my_activity.activity_history' | translate}}</div>
        <cl-daily-minutes-activity-card
          *ngFor="let getDailyKey of getDailyKeys | slice:0:shownDailyActivities"
          [activities]="(getDailyActivity$ | async)![getDailyKey]"
        >
        </cl-daily-minutes-activity-card>
        <ion-row
          class="action-button"
          *ngIf="getDailyKeys?.length > shownDailyActivities"
          (click)="showMoreDailyActivities()">
          <ion-col class="ion-text-center" >
            <ion-button
              class="dots"
              color="white"
              size="small"
            >
              ...
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
      <div class="integration-section" *ngIf="!(integrationConnected$ | async)">
        <span>{{'my_activity.no_devices_connected' | translate}} </span>
        <span (click)="goToMyDeviceSection()" class="devices-link">{{'my_activity.click_here' | translate}} </span>
        <span>{{'my_activity.add_tracker' | translate}}</span>
      </div>
    </div>
  `
})
export class ActivityComponent implements OnInit, OnDestroy {
  @Output() activityEnd = new EventEmitter();

  getTodaysMinutesActivitySum$ = this.stateStore.select(getTodaysMinutesActivitySum);
  getTodayManualMinutesActivity$ = this.stateStore.select(getTodayManualMinutesActivity);
  getDailyActivity$ = this.stateStore.select(getDailyActivity);
  getDailyKeys = [];
  integrationSource = IntegrationSource;

  isHealthKitConnected$ = this.stateStore.select(isHealthKitConnected);
  isGoogleFitConnected$ = this.stateStore.select(isGoogleFitConnected);
  isFitBitConnected$ = this.stateStore.select(isFitbitConnected);
  integrationConnected$ = this.stateStore.select(isIntegrationConnected);
  showInformationDialog$ = this.stateStore.select(getShowInformationDialog);
  userProgramCreationDate: Date;

  canOrderFitbitTracker$ = this.stateStore.select(canOrderFitbitTracker);

  isTrackerRedemptionEnabled$ = this.stateStore.select(getIsTrackerRedemptionEnabled);

  shownMinutesActivities = 7;
  shownDailyActivities = 7;
  visibility = 'visible';

  private subscriptions: Subscription[] = [];

  showMoreDailyActivities() {
    this.shownDailyActivities += 7;
  }

  showMoreMinutesActivities() {
    this.shownMinutesActivities += 7;
  }

  get today() {
    return format(new Date(), 'MMMM do, yyyy');
  }

  constructor(
    private translate: TranslateService,
    public alertController: AlertController,
    private stateStore: Store<State>,
    private nav: NavController,
    public config: ClarityConfig,
    private store: Store<SessionState>
  ) {}

  ngOnInit() {
    this.stateStore.select(getCurrentUserProgram).pipe(take(1))
      .subscribe(userProgram => {
        this.userProgramCreationDate = new Date(userProgram.created_at);
      });

    this.subscriptions.unshift(this.getDailyActivity$.subscribe(dailyActivity =>  {
      this.getDailyKeys = Object.keys(dailyActivity);
    }));

    this.showInformationDialog$.pipe(take(1)).subscribe(showDialog => {
      const platform = showDialog?.fitbit ? 'fitbit' : showDialog?.googleFit ? 'android' : showDialog?.healthKit ? 'ios' : null;

      if(platform) {
        const integrationResult: IntegrationInformation = {platform, type: 'information', status: null};
        this.stateStore.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      }
    });
  }

  getFitbitTracker() {
    this.store.dispatch(new integrationsActions.OrderFitbitTracker());
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.activityEnd.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  async addMinutesMannualy() {
    this.translate.get(
      ['my_activity.add_daily_minutes', 'my_activity.time_in_minutes' ,'common.submit', 'common.cancel']
    )
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          header: translations['my_activity.add_daily_minutes'],
          inputs: [
            {
              name: 'minutes',
              type: 'number',
              placeholder: translations['my_activity.time_in_minutes']
            },
            {
              name: 'date',
              type: 'datetime-local',
              min: format(this.userProgramCreationDate, 'yyyy-MM-dd\'T\'HH:mm'),
              max: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm'),
              value: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm')
            }],
          buttons: [
            {
              text: translations['common.cancel']
            },
            {
              text: translations['common.submit'],
              handler: data => {
                data.minutes && this.stateStore.dispatch(new AddManualMinutesActivity(data.minutes, new Date(data.date)));
              }
            }]
        });

        await alert.present();
      });
  }

  goToMyDeviceSection() {
    this.nav.navigateRoot('tabs/account/devices');
  }

  openConnectedDeviceModal() {
    combineLatest([
      this.isHealthKitConnected$,
      this.isGoogleFitConnected$,
      this.isFitBitConnected$
    ]).pipe(take(1))
      .subscribe(([ios, android, fitbit]) => {
        const platform = ios ? 'ios' : android ? 'android' : fitbit ? 'fitbit' : null;

        const integrationResult: IntegrationInformation = {platform, type: 'information', status: null};
        this.stateStore.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
