import { Action, ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { NormalizedState, normalized } from 'ngrx-normalizr';
import { PersistentState } from './persistent/persistent.reducers';
import { SessionState } from './session/session.reducers';
import { SensitiveState } from './sensitive/sensitive.reducers';
import { AuthState } from './sensitive/reducers/auth.reducer';

export interface HydratedState { }

export function hydratedReducer(state = false, action: Action): HydratedState {
  return state;
}

export interface State extends NormalizedState {
  hydrated: HydratedState;
}

// these keys gets added to the RootState when the .forFeature() is called
export interface FullState extends State {
  sensitive: SensitiveState;
  session: SessionState;
  persistent: PersistentState;
}

export const reducer: ActionReducerMap<State> = {
  hydrated: hydratedReducer,
  normalized
};

export const getSensitiveState = createFeatureSelector<SensitiveState>('sensitive');
export const getSessionState = createFeatureSelector<SessionState>('session');
export const getPersistentState = createFeatureSelector<PersistentState>('persistent');

export const getAccountState = createSelector(getSessionState, (state) => state.account);

export const getUnsecureAuthState = createSelector(getSessionState, (state) => (state as any).auth);
export const getSecureAuthState = createSelector(getSensitiveState, (state) => state.auth);

// unsecureAuth will only be used only once, when launching for the time the secure storage version of the app
// do not remove it while still having active users for app version with unsecure (auth) storage !
export const getAuthState = createSelector(
  getSecureAuthState,
  getUnsecureAuthState,
  (secureAuth, unsecureAuth): AuthState => {
    if (secureAuth?.token) {
      return secureAuth;
    }

    // this state will happen during migration, on first launch
    if (unsecureAuth?.token) {
      return unsecureAuth;
    }

    // user has did migration on the past already (session.auth is empty),
    // but is not authenticated (sensitive.auth.token is null),
    // so we return the default state
    return secureAuth;
  }
);


export const getNowState = createSelector(getSessionState, (state) => state.now);
export const getOfflineQueueState = createSelector(getSessionState, (state) => state.offlineQueue);
export const getDownloadState = createSelector(getSessionState, (state) => state.download);
export const getProgramState = createSelector(getSessionState, (state) => state.program);
export const getSyncState = createSelector(getSessionState, (state) => state.sync);
export const getExercisesState = createSelector(getSessionState, (state) => state.exercises);
export const getUserProfileState = createSelector(getSessionState, (state) => state.userProfile);
export const getUserProgramState = createSelector(getSessionState, (state) => state.userProgram);
export const getUserGoalsState = createSelector(getSessionState, (state) => state.userGoals);
export const getUserFavoritesState = createSelector(getSessionState, (state) => state.userFavs);
export const getUserActivitiesState = createSelector(getSessionState, (state) => state.userActivities);
export const getCigTrackerState = createSelector(getSessionState, (state) => state.cigTraker);
export const getUserRemindersState = createSelector(getSessionState, (state) => state.userReminders);
export const getSocialState = createSelector(getSessionState, (state) => state.social);
export const getSubscriptionState = createSelector(getSessionState, (state) => state.subscription);
export const getPostState = createSelector(getSessionState, (state) => state.post);
export const getNotificationState = createSelector(getSessionState, (state) => state.notifications);
export const getNrtState = createSelector(getSessionState, (state) => state.nrt);
export const getMyCoachState = createSelector(getSessionState, (state) => state.myCoach);
export const getIridiumState = createSelector(getSessionState, (state) => state.iridium);
export const getIntegrationsState = createSelector(getSessionState, (state) => state.integrations);
export const getHealthDevicesState = createSelector(getSessionState, (state) => state.healthDevices);

export const getMediaState = createSelector(getPersistentState, (state) => state.media);
export const getFlagsState = createSelector(getPersistentState, (state) => state.flags);
export const getTabsState = createSelector(getPersistentState, (state) => state.tabs);
export const getInterstitialsState = createSelector(getPersistentState, (state) => state.interstitials);
export const getOnboardingState = createSelector(getPersistentState, (state) => state.onboarding);
export const getTimestampsState = createSelector(getPersistentState, (state) => state.timestamps);
