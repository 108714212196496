export interface MenuItemOptions {
  title: string;
  template?: string;
  componentProps?: Record<string, any>;
  order?: number;
  action?: string;
  menu?: MenuItem;
  options?: Record<string, MenuItemOptions>; // recursive
  hasBadgeSlot?: boolean;
  onlyMobile?: boolean;
  [key: string]: any;
}

export interface MenuItem {
  title: string;
  subTitle?: string;
  subscription?: boolean;
  options?: Record<string, MenuItemOptions>;
  [key: string]: any;
}


export type Menu = Record<string, MenuItem>;

export const accountMenuItems: Menu = {
  // Eat Right Now menu
  ernWl: {
    title: 'account_menu.settings.title',
    subTitle: 'account_menu.settings.subtitle',
    options: {
      stats: {
        title: 'account_menu.stats.title',
        order: 1,
        componentProps: {
          isDesktop: true
        },
        template: 'MyStatsComponent'
      },
      account: {
        title: 'account_menu.account.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'account',
          setMenu: true
        },
        order: 2,
        menu: {
          title: 'account_menu.account.title',
          options: {
            profile: {
              title: 'account_menu.profile.title',
              template: 'ProfileWebComponent'
            },
            password: {
              title: 'auth.change_password',
              template: 'ChangePasswordComponent',
              hideOnSSO: true
            },
            goals: {
              title: 'account_menu.goals.title',
              template: 'GoalSettingsComponent'
            },
            language: {
              title: 'account_menu.language.title',
              template: 'LanguageComponent'
            },
            downloads: {
              title: 'account_menu.downloads.title',
              template: 'DownloadComponent',
              onlyMobile: true
            }
          }
        }
      },
      subscription: {
        title: 'account_menu.subscription.title',
        order: 4,
        componentProps: {
          forceDesktop: true
        },
        hideOnSSO: true,
        template: 'SubscriptionSettingsComponent'
      },
      weight: {
        title: 'account_menu.tracking.title',
        order: 3,
        template: 'WeightTrackComponent',
        hasBadgeSlot: true
      },
      devices: {
        title: 'account_menu.devices.title',
        order: 4,
        template: 'IntegrationsComponent'
      },
      activity: {
        title: 'account_menu.activity.title',
        order: 5,
        template: 'ActivityComponent'
      },
      reminders: {
        title: 'account_menu.reminders.title',
        order: 6,
        componentProps: {
          isDesktop: true
        },
        template: 'RemindersComponent'
      },
      help: {
        title: 'account_menu.help.title',
        template: 'HelpComponent',
        order: 7,
        options: {
          onboarding: {
            title: 'account_menu.help.title',
            template: 'OnboardingListComponent'
          }
        },
        onlyMobile: true
      },
      about: {
        title: 'account_menu.about.title',
        order: 8,
        template: 'AboutComponent',
        onlyMobile: true
      }
    }
  },
  ern: {
    title: 'account_menu.settings.title',
    subTitle: 'account_menu.settings.subtitle',
    options: {
      stats: {
        title: 'account_menu.stats.title',
        order: 1,
        componentProps: {
          isDesktop: true
        },
        template: 'MyStatsComponent'
      },
      account: {
        title: 'account_menu.account.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'account',
          setMenu: true
        },
        order: 2,
        menu: {
          title: 'account_menu.account.title',
          options: {
            profile: {
              title: 'account_menu.profile.title',
              template: 'ProfileWebComponent'
            },
            password: {
              title: 'auth.change_password',
              template: 'ChangePasswordComponent',
              hideOnSSO: true
            },
            goals: {
              title: 'account_menu.goals.title',
              template: 'GoalSettingsComponent'
            },
            language: {
              title: 'account_menu.language.title',
              template: 'LanguageComponent'
            },
            downloads: {
              title: 'account_menu.downloads.title',
              template: 'DownloadComponent',
              onlyMobile: true
            }
          }
        }
      },
      subscription: {
        title: 'account_menu.subscription.title',
        order: 4,
        componentProps: {
          forceDesktop: true
        },
        hideOnSSO: true,
        template: 'SubscriptionSettingsComponent'
      },
      weight: {
        title: 'account_menu.tracking.title',
        order: 3,
        template: 'WeightTrackComponent',
        hasBadgeSlot: true
      },
      reminders: {
        title: 'account_menu.reminders.title',
        order: 6,
        componentProps: {
          isDesktop: true
        },
        template: 'RemindersComponent'
      },
      help: {
        title: 'account_menu.help.title',
        template: 'HelpComponent',
        order: 7,
        options: {
          onboarding: {
            title: 'account_menu.help.title',
            template: 'OnboardingListComponent'
          }
        },
        onlyMobile: true
      },
      about: {
        title: 'account_menu.about.title',
        order: 8,
        template: 'AboutComponent',
        onlyMobile: true
      }
    }
  },

  // Eat Right Now menu with health fit integration enabled
  ernHealthfit: {
    title: 'account_menu.settings.title',
    subTitle: 'account_menu.settings.subtitle',
    options: {
      stats: {
        title: 'account_menu.stats.title',
        order: 1,
        componentProps: {
          isDesktop: true
        },
        template: 'MyStatsComponent'
      },
      account: {
        title: 'account_menu.account.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'account',
          setMenu: true
        },
        order: 2,
        menu: {
          title: 'account_menu.account.title',
          options: {
            profile: {
              title: 'account_menu.profile.title',
              template: 'ProfileWebComponent'
            },
            password: {
              title: 'auth.change_password',
              template: 'ChangePasswordComponent',
              hideOnSSO: true
            },
            goals: {
              title: 'account_menu.goals.title',
              template: 'GoalSettingsComponent'
            },
            language: {
              title: 'account_menu.language.title',
              template: 'LanguageComponent'
            },
            downloads: {
              title: 'account_menu.downloads.title',
              template: 'DownloadComponent',
              onlyMobile: true
            }
          }
        }
      },
      subscription: {
        title: 'account_menu.subscription.title',
        order: 4,
        componentProps: {
          forceDesktop: true
        },
        hideOnSSO: true,
        template: 'SubscriptionSettingsComponent'
      },
      weight: {
        title: 'account_menu.tracking.title',
        order: 3,
        template: 'WeightTrackComponent',
        hasBadgeSlot: true
      },
      devices: {
        title: 'account_menu.devices.title',
        order: 4,
        template: 'IntegrationsComponent'
      },
      reminders: {
        title: 'account_menu.reminders.title',
        order: 6,
        componentProps: {
          isDesktop: true
        },
        template: 'RemindersComponent'
      },
      help: {
        title: 'account_menu.help.title',
        template: 'HelpComponent',
        order: 7,
        options: {
          onboarding: {
            title: 'account_menu.help.title',
            template: 'OnboardingListComponent'
          }
        },
        onlyMobile: true
      },
      about: {
        title: 'account_menu.about.title',
        order: 8,
        template: 'AboutComponent',
        onlyMobile: true
      }
    }
  },

  // Weight loss & diabetes prevention program
  dppwl: {
    title: 'account_menu.settings.title',
    subTitle: 'account_menu.settings.subtitle',
    options: {
      stats: {
        title: 'account_menu.stats.title',
        order: 1,
        componentProps: {
          isDesktop: true
        },
        template: 'MyStatsComponent'
      },
      account: {
        title: 'account_menu.account.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'account',
          setMenu: true
        },
        order: 2,
        menu: {
          title: 'account_menu.account.title',
          options: {
            profile: {
              title: 'account_menu.profile.title',
              template: 'ProfileWebComponent'
            },
            password: {
              title: 'auth.change_password',
              template: 'ChangePasswordComponent',
              hideOnSSO: true
            },
            goals: {
              title: 'account_menu.goals.title',
              template: 'GoalSettingsComponent'
            },
            language: {
              title: 'account_menu.language.title',
              template: 'LanguageComponent'
            },
            downloads: {
              title: 'account_menu.downloads.title',
              template: 'DownloadComponent',
              onlyMobile: true
            }
          }
        }
      },
      subscription: {
        title: 'account_menu.subscription.title',
        order: 4,
        componentProps: {
          forceDesktop: true
        },
        hideOnSSO: true,
        template: 'SubscriptionSettingsComponent'
      },
      weight: {
        title: 'account_menu.weight.title',
        order: 3,
        template: 'WeightTrackComponent',
        hasBadgeSlot: true
      },
      devices: {
        title: 'account_menu.devices.title',
        order: 4,
        template: 'IntegrationsComponent'
      },
      activity: {
        title: 'account_menu.activity.title',
        order: 5,
        template: 'ActivityComponent'
      },
      reminders: {
        title: 'account_menu.reminders.title',
        order: 6,
        componentProps: {
          isDesktop: true
        },
        template: 'RemindersComponent'
      },
      help: {
        title: 'account_menu.help.title',
        template: 'HelpComponent',
        order: 7,
        options: {
          onboarding: {
            title: 'account_menu.help.title',
            template: 'OnboardingListComponent'
          }
        },
        onlyMobile: true
      },
      about: {
        title: 'account_menu.about.title',
        order: 8,
        template: 'AboutComponent',
        onlyMobile: true
      }
    }
  },

  // Unwinding Anxiety menu
  ua: {
    title: 'account_menu.settings.title',
    subTitle: 'account_menu.settings.subtitle',
    options: {
      stats: {
        title: 'account_menu.stats.title',
        order: 1,
        template: 'MyStatsComponent',
        componentProps: {
          isDesktop: true
        }
      },
      account: {
        title: 'account_menu.account.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'account',
          setMenu: true
        },
        order: 2,
        menu: {
          title: 'account_menu.account.title',
          options: {
            profile: {
              title: 'account_menu.profile.title',
              template: 'ProfileWebComponent'
            },
            password: {
              title: 'auth.change_password',
              template: 'ChangePasswordComponent',
              hideOnSSO: true
            },
            language: {
              title: 'account_menu.language.title',
              template: 'LanguageComponent'
            },
            downloads: {
              title: 'account_menu.downloads.title',
              template: 'DownloadComponent',
              onlyMobile: true
            }
          }
        }
      },
      goals: {
        title: 'account_menu.goals.title',
        order: 3,
        componentProps: {
          forceDesktop: true
        },
        template: 'GoalSettingsComponent'
      },
      subscription: {
        title: 'account_menu.subscription.title',
        order: 4,
        componentProps: {
          forceDesktop: true
        },
        hideOnSSO: true,
        template: 'SubscriptionSettingsComponent'
      },
      reminders: {
        title: 'account_menu.reminders.title',
        order: 4,
        componentProps: {
          isDesktop: true
        },
        template: 'RemindersComponent'
      },
      help: {
        title: 'account_menu.help.title',
        template: 'HelpComponent',
        order: 5,
        options: {
          onboarding: {
            title: 'account_menu.help.title',
            template: 'OnboardingListComponent'
          }
        },
        onlyMobile: true
      },
      about: {
        title: 'account_menu.about.title',
        order: 6,
        template: 'AboutComponent',
        onlyMobile: true
      }
    }
  },

  // Craving To Quit menu
  ctq: {
    title: 'account_menu.settings.title',
    subTitle: 'account_menu.settings.subtitle',
    options: {
      stats: {
        title: 'account_menu.stats.title',
        componentProps: {
          isDesktop: true
        },
        order: 1,
        template: 'MyStatsComponent'
      },
      quit: {
        title: 'account_menu.quit.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'quit',
          setMenu: true
        },
        order: 2,
        menu: {
          title: 'account_menu.account.title',
          options: {
            goals_ctq: {
              title: 'account_menu.goals_ctq.title',
              componentProps: {
                forceDesktop: true
              },
              template: 'GoalSettingsComponent'
            },
            nrt: {
              title: 'account_menu.nrt.title',
              action: 'openNrtPage',
              showOnSubscription: true,
              template: ''
            },
            my_coach: {
              title: 'inbox.notifications.my_coach',
              action: 'openMyCoach'
            },
            weekly_calls: {
              title: 'community.weekly_calls',
              action: 'openWeeklyVideo'
            }
          }
        }
      },
      reminders: {
        title: 'account_menu.reminders.title',
        order: 3,
        componentProps: {
          isDesktop: true
        },
        template: 'RemindersComponent'
      },
      account: {
        title: 'account_menu.account.title',
        template: 'SettingsWebComponent',
        componentProps: {
          menuRoot: 'account',
          setMenu: true
        },
        order: 4,
        menu: {
          title: 'account_menu.account.title',
          options: {
            profile: {
              title: 'account_menu.profile.title',
              template: 'ProfileWebComponent'
            },
            password: {
              title: 'auth.change_password',
              template: 'ChangePasswordComponent',
              hideOnSSO: true
            },
            community_avatar: {
              title: 'account_menu.community.avatar_card_title',
              action: 'openCommunityProfileForm'
            },
            language: {
              title: 'account_menu.language.title',
              componentProps: {
                forceDesktop: true
              },
              template: 'LanguageComponent'
            },
            downloads: {
              title: 'account_menu.downloads.title',
              template: 'DownloadComponent',
              onlyMobile: true
            }
          }
        }
      },
      subscription: {
        title: 'account_menu.subscription.title',
        componentProps: {
          forceDesktop: true
        },
        hideOnSSO: true,
        template: 'SubscriptionSettingsComponent',
        order: 5
      },
      anniversariesAndPledges: {
        title: 'account_menu.anniversaries_and_pledges.title',
        disabled: true,
        template: ''
      },
      help: {
        title: 'account_menu.help.title',
        template: 'HelpComponent',
        order: 6,
        options: {
          onboarding: {
            title: 'account_menu.help.title',
            template: 'OnboardingListComponent'
          }
        },
        onlyMobile: true
      },
      about: {
        title: 'account_menu.about.title',
        order: 7,
        template: 'AboutComponent',
        onlyMobile: true
      }
    }
  }
};
